.adm-toast-mask .adm-toast-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}
.adm-toast-mask .adm-toast-main {
  display: inline-block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: auto;
  max-width: 204px;
  max-height: 70%;
  overflow: auto;
  color: white;
  word-break: break-all;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 8px;
  pointer-events: all;
  font-size: var(--adm-font-size-7);
  line-height: 1.5;
  box-sizing: border-box;
  text-align: initial;
}
.adm-toast-mask .adm-toast-main-text {
  padding: 12px;
  min-width: 0px;
}
.adm-toast-mask .adm-toast-main-icon {
  padding: 35px 12px;
  min-width: 150px;
}
.adm-toast-mask .adm-toast-main-icon .adm-toast-icon {
  text-align: center;
  margin-bottom: 8px;
  font-size: 36px;
  line-height: 1;
}
.adm-toast-loading {
  --size: 48px;
  margin: 0 auto 8px;
}
